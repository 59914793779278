import React from 'react'
//import { Link } from 'gatsby';
import Link from '../components/Link'
import { Col, Row, Container, Button } from 'react-bootstrap'
import '../css/signup.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PaymentError = ({ redirectUrl = '/signup' }) => {
	return (
		<Layout>
			<SEO title="Signup" />
			<div className="Header-parent">
				{' '}
				<Row>
					<h1>Uh oh, something went wrong :(</h1>
				</Row>
				<Row>
					<p>
						We weren't able to process the payment. Please try again or contact us so we
						can get you back to your subscription.
					</p>
				</Row>
				<Row>
					<Link to={redirectUrl} data-track-location="paymentError">
						<Button>Try Again</Button>
					</Link>

					<Link to="/contactus">
						<Button>Contact Us</Button>
					</Link>
				</Row>
			</div>
		</Layout>
	)
}

export default PaymentError
